<template lang="pug">
.search-results
  script(type="application/ld+json")
    | {{ seo_json_data }}
  .medium-show
    br
    br
  .summary.grid.collapse-padding.half-edge.align-top
    .cell.fit.filter-cell.order-1
      .search-filters-container
        FilterModal(:zonesUrl="zonesUrl", 
                    :introJsEnabled="introJsEnabled",
                    :ypoUser="ypoUser", 
                    :ypoOnly="ypoOnly",
                    ref='filters')
    .cell.fit.medium-show.logged-out-invisible.order-2(v-show="canViewAvailability")
      .pill.select-pill
        label.strong View:
        select#search-type(@change="switchSearchType" style="padding-left:7ch" :value='searchType')
          option(value="properties") Properties
          option(value="availability") Availability
          option(value="map") Map
    .cell.search-sort.fit.order-2.medium-order-3(:class="{ 'map-type': searchType === 'map' }") 
      .pill.select-pill(for="search-sort")
        label.strong Sort:
        select#search-sort(:value="sort_type" @change="changeSortType")
          option(v-if="searchType === 'properties'"
                value="featured") Featured
          option(v-if="searchType === 'availability'"
                value="chron") Chronologically
          option(value="recent") Recently Added
          option(v-if="loggedIn"
                value="rating") Rating
          option(value="keys_desc") Keys: High to Low
          option(value="keys_asc") Keys: Low to High
          option(value="beds_desc") Beds: High to Low
          option(value="beds_asc") Beds: Low to High
    .cell.fit.medium-show#keyless-stays.order-4(v-if='loggedIn && !promoFilterFeatureActive')
      a(href="/properties?keyless=true&ref=index&search_type=availability&sort_type=recent")
        .pill.icon-pill
          .pill-icon
            span.keyless-stays-icon
          span.pill-text
            | Keyless 
            span.medium-show Stays
    .cell.fit.medium-show#current-promos.order-4(v-if='loggedIn && promoFilterFeatureActive')
      a(:href='promotionUrl')
        .pill.icon-pill
          .pill-icon(style="color:black")
            span.third-font-ticket
          span.pill-text
            | Current 
            span.medium-show Promos

    .cell.fill.medium-show.order-5
    .cell.fit.text-right.medium-show.order-6
      SaveBar(v-if='loggedIn' :searches='savedSearches')
    .cell.twelve.order-4.medium-order-7
      br
    .cell.fit.filter-stats.order-8(v-if="desktopFilterCount > 0") 
      .properties-count-container
        small.results-count 
          span.properties-count Active Filters:
    .cell.fit.filter-stats.order-8.hide
      .properties-count-container
        small.results-count
          span.properties-count(v-if="!loading") {{ resultCountDescription }}
    .cell.twelve.medium-fill.order-9
      .filter-pill-container(style="display:inline-block; margin-left:-5px" v-show="desktopFilterCount > 0")
        .pill.tiny.tag.removable(v-for="(value, key) in desktopFilters") {{ filterHumanCopy[value] }}
          span.remove.close(@click.stop="removeFilter(value)")
            .x

        a.desktop-clear.blue-text.underline(@click.prevent="desktopClear") 
          span Clear Filters
    .cell.fill.medium-fit.availability-control.logged-out-invisible.order-3.medium-order-10(v-show="canViewAvailability && searchType !== 'availability'")     
      span.availability-stour(data-step="3" data-title="No results? That's OK" data-intro="Your fellow members add 800+ new weeks every month! Toggle to see only what is availabile today.")
        .input.check_boxes.availability-toggle(v-show="stayDurationSelection === null" style="margin-bottom:0")
          span.checkbox
            input(type="checkbox"
                  :checked="properties_with_availability"
                  :disabled="dateIsSelected"
                  @change="toggleAvailability"
                  id="availbility_toggle")
            label(for="availbility_toggle" style="padding-left:2px")
              small
                span.medium-show Only show properties with availability 
                span.medium-hide Has availability

        .input.check_boxes.option-disabled.availability-toggle(v-show="stayDurationSelection !== null" style="margin-bottom:0")
          span.checkbox
            input(type="checkbox"
                  :checked="true"
                  :disabled="true"
                  id="availbility_toggle_range")
            label(for="availbility_toggle_range" style="padding-left:2px")
              small
                span.medium-show Only show properties
                span  with availability

  .results(:class="{ loading, [searchType]: true }")
    .loader-container
      br
      br
      .loader
      .loader-text
        span L
        span o
        span a
        span d
        span i
        span n
        span g
        span &nbsp;&nbsp;R
        span e
        span s
        span u
        span l
        span t
        span s
      br
      br
      br
      br
    .all-entries
      .empty-results.empty.result-entries(v-if="resultsCount === 0")
        .results-message.text-center
          br
          br
          h5 {{ emptyCriteriaMessage }}
      #results-pane-map(:class="{ 'hide-search-map': loading || searchType !== 'map' && !hasFallbackMap }")
        PropertyMap(ref='map' @move="updateMapHash")
      #results-pane-properties(ref="propertiesResults" v-show="!loading && searchType !== 'map'")
        .result-entries(v-html="resultsContent"
                        @click="showClick")
</template>

<script>
import { get, sync, call } from 'vuex-pathify'
import PropertyMap from './PropertyMap.vue'
import StarButton from '../SaveBar/StarButton.vue'
import SaveBar from '../SaveBar/SaveBar.vue'
import FilterModal from '../FilterModal/FilterModal.vue'

export default {
  components: {
    PropertyMap,
    StarButton,
    SaveBar,
    FilterModal
  },
  props: {
    zonesUrl:  { type: String, required: true },
    introJsEnabled: {type: Boolean, default: false },
    savedSearches: {  type: Array, default: () => [] },
    ypoUser: { type: Boolean, default: false},
    ypoOnly: { type: Boolean, default: false},
    promoFilterFeatureActive: { type: Boolean, default: true },
    promotionUrl: { type: String, required: false }
  },
  computed: {
    searchType: sync('browseHomes/filters@search_type'),
    startDate: sync('browseHomes/filters@start_date'),
    endDate: sync('browseHomes/filters@end_date'),
    filterHumanCopy: sync('browseHomes/filterHumanCopy'),
    ...sync('browseHomes', [
      'loggedIn',
      'lastSearch',
      'filters',
      'results',
      'resultsCount',
      'loading',
      'properties_with_availability',
      'map_all_properties',
      'canViewAvailability',
      'sort_type',
      'seo_json_data',
      'hasFallbackMap'
    ]),
    mapLocation: get('browseHomes/mapLocation'),
    filtersActive: get('browseHomes/filtersActive'),
    desktopFilters: get('browseHomes/desktopFilters'),
    stayDurationSelection: get('browseHomes/stayDurationSelection'),
    desktopFilterCount: get('browseHomes/desktopFilterCount'),
    resultsContent () {
      return this.results ? this.results.results : ''
    },
    resultCountDescription () {
      const count = this.resultsCount
      if (count === null || count <= 0) return ''
      if (this.searchType === 'availability') {
        return `${count} Stay${count > 1 ? 's' : ''}`
      } else {
        return `${count} Propert${count === 1 ? 'y' :'ies'}`
      }
    },
    emptyCriteriaMessage () {
      if (this.hasFallbackMap) {
        return "There are no results found. You can save this search to receive alerts for any updates. We have provided alternative destination options for the dates you've specified below."
      }
      else if (this.searchType === 'properties' || this.searchType === 'availability') {
        return 'No results. Save this search to be alerted of any changes.'
      } else {
        return ''
      }
    },
    mapSelected () {
      return this.searchType === 'map'
    },
    loggedInVisisble () {
      return {
        'logged-out-invisible': !this.loggedIn
      }
    },
    dateIsSelected () {
      return this.startDate || this.endDate || false
    }
  },
  watch: {
    results (results) {
      if (results.search_type === 'map' || this.hasFallbackMap) {
        this.setMapData()
      }
    },
  },
  mounted () {
    if (this.searchType === 'map' || this.hasFallbackMap) {
      this.setMapData()
    }
  },
  methods: {
    ...call('browseHomes', [
      'fetchProperties',
      'updateMapHash',
      'resetFiltersFromLastSearch',
      'filterCopy'
    ]),
    switchSearchType (e) {
      const newSearchType = e.target?.value
      if (newSearchType == this.searchType) return

      this.searchType = newSearchType
      this.fetchProperties()
    },
    toggleAvailability (e) {
      this.properties_with_availability = e.target.checked
      this.fetchProperties()
    },
    toggleMapAllProperties (e) {
      this.map_all_properties = !e.target.checked
      this.fetchProperties()
    },
    changeSortType (e) {
      this.sort_type = e.target.value
      this.fetchProperties()
    },
    showClick (e) {
      if (e.target.href && e.target.closest('nav.pagination') !== null) {
        e.preventDefault()
        this.fetchProperties({ url: e.target.href })
      }
    },
    
    setMapData () {
      this.$refs.map.loadData(this.results, this.mapLocation)
    },
    desktopClear () {
      this.$refs.filters.desktopClear()
    },
    removeFilter (filterName) {
      this.filters[filterName] = false
      this.fetchProperties()
    },
  }
}
</script>
